'use client';

import loadable from '@loadable/component';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as C from './styles';
import { Button } from '../../../../styles/shared';
import { bannersList } from '../../../infos/banners';
import bubbles from './bubbles.module.scss';
import { useMemo } from 'react';

/**
 * Hero Section
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @returns JSX.Element
 */

// Lazy loading components
const ParticlesBackground = loadable(() => import('./Particles'), {});
const Slider = loadable(() => import('react-slick'), {
  fallback: <div />,
});

const AppendDots = ({ dots }: { dots: any }) => <C.BannerDots>{dots}</C.BannerDots>;

export default function Hero() {
  const bannerSettings = useMemo(
    () => ({
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      appendDots: AppendDots,
    }),
    [],
  );

  return (
    <C.BannerWrapper>
      <div className="container">
        <Slider {...bannerSettings}>
          {bannersList.map(({ id, title, subtitle }) => (
            <C.Banner key={id}>
              <C.BannerTitle>{title}</C.BannerTitle>
              <C.BannerSubTitle>{subtitle}</C.BannerSubTitle>
            </C.Banner>
          ))}
        </Slider>

        <C.BannerDivider />

        <Button className="banner__button" href="/contato">
          Entrar em contato
        </Button>
      </div>

      <ParticlesBackground />

      {/* CSS Bubbles */}
      {Array(100)
        .fill(1)
        .map((el, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={bubbles.container} key={i}>
            <div className={bubbles.bubble} />
          </div>
        ))}
    </C.BannerWrapper>
  );
}
